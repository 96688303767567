
import { Component, Vue } from 'vue-property-decorator'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import KionInput from '@/components/Shared/Input.vue'
import {httpService, tvhEnvironmentApi} from "@/util/HttpAdapter";
import {gtm} from "@/util/GTMService";
import {v4 as uuidv4} from "uuid";
import {Subscription} from '@/util/promo'
import { mainService } from '@/util/mainService';
import KionCard from '@/components/Shared/Cards/Card.vue';

@Component({
  components: {
    KionTemplate,
    KionButton,
    KionInput,
		KionCard
  }
})
export default class KionCode extends Vue {
  qr: string | null = null
  code = ''
  activeCode = httpService.promoCode
  codeErrorMsg: string | null = null
	isCodeError = false
  loading = false
  subscriptions: Subscription[] = []

  mounted() {
	const queryParam = location.search;
	const queryPromocode = queryParam.includes('pq=');
	if (queryPromocode) {
		mainService.isFooterVisible.next(false);
	}
    window.sessionStorage.removeItem("selected_subscription");
	this.activeCode = httpService.promoCode;
	this.subscriptions = httpService.subscriptions;
	
    const q = this.$route.query
    if (typeof q?.pq === 'string') {
      this.code = q.pq.trim().toUpperCase()
      this.activeCode = q.pq.trim().toUpperCase()
    }
    if (typeof q?.PQ === 'string') {
      this.code = q.PQ.trim().toUpperCase()
      this.activeCode = q.PQ.trim().toUpperCase()
    }
  }

  get isButtonEnable(): boolean {
    return !!this.code
  }

  webSsoAuth(subscription: Subscription) {
    window.sessionStorage.setItem("request_id", uuidv4())
	window.sessionStorage.setItem("selected_subscription", JSON.stringify(subscription))
    gtm.pushEvent({
      event: 'authorization_start',
      event_name: 'authorization_start',
      referer: httpService.previousPage,
      auth_method: "sms",
      auth_type: 'websso',
      request_id: window.sessionStorage.getItem("request_id")
    })
    const hostUrl = window.location.host
    window.location.assign(encodeURI(`https://${tvhEnvironmentApi}/tvh-public-api-gateway/public/rest/general/websso/redirect?state=0YHC1ES5OE80&clientType=WEB&domain=${hostUrl}`))
  }

	checkPromocode() {
		gtm.pushEvent({
			event: 'landing_button_click',
			event_name: 'landing_button_click',
			button_id: "activate",
			button_text: "Активировать",
		});

		if (this.code === httpService.currentPromoCode) {
			this.activeCode = this.code;
			httpService.promoCode = this.code;
			this.code = '';
			this.codeErrorMsg = null;
			this.isCodeError = false;
			this.subscriptions = httpService.subscriptions;
			mainService.isFooterVisible.next(false);
		} else {
			this.setCodeErrors();
			this.isCodeError = true;
			this.codeErrorMsg = 'Промокод не найден';
		}
	}	

  setCodeErrors(error?: string) {
    this.code = this.code.trim()
    if (error) {
      this.codeErrorMsg = error
      return
    }

    if (!this.code) {
      this.codeErrorMsg = 'Обязательное поле'
      return
    }

    this.codeErrorMsg = null
  }
}
