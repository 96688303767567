
import { Component, Vue } from 'vue-property-decorator'
import KionFooter from '@/components/Footer/Footer.vue'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'
import { httpService } from '@/util/HttpAdapter'
import { KION_ERROR_PAGE_TYPE } from '@/util/interfaces'

interface ErrorScheme {
  title: string,
  msg?: string,
  btnName: string
  action: () => void
}

@Component({
  components: {
    Template,
    KionFooter,
    KionButton,
    KionTemplate
  }
})
export default class KionError extends Vue {
  errorsMap: Map<KION_ERROR_PAGE_TYPE, ErrorScheme> = new Map([
    [KION_ERROR_PAGE_TYPE.LIMIT_REACHED,
      {
        title: 'Превышено число устройств',
        msg: `К учетной записи МТС ТВ можно подключить не более 5 устройств. Удалите устройство и попробуйте снова`,
        btnName: 'Перейти в настройки',
        action: () => this.$router.push('/')
      }
    ],
    [KION_ERROR_PAGE_TYPE.IMPOSSIBLE_TO_CONNECT,
      {
        title: 'Подключение невозможно',
        msg: `У Вас уже подключен пакет «Онлайн-кинотеатры». Для <br> подключения пакета «Развлекательное» необходимо отключить пакет <br> «Онлайн-кинотеатры». Совместное использование этих пакетов невозможно`,
        btnName: 'Перейти в настройки',
        action: () => this.$router.push('/')
      }
    ],
    [KION_ERROR_PAGE_TYPE.UNKNOWN_OPERATOR,
      {
        title: 'Неподдерживаемый оператор сотовой связи',
        msg: `Номер телефона принадлежит оператору сотовой связи, который не поддерживается в соответствии с условиями участия в акции. Введите другой номер телефона или приобретите SIM-карту оператора МТС`,
        btnName: 'Показать карту салонов МТС',
        action: () => window.location.href = 'https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/offices/'
      }
    ],
    [KION_ERROR_PAGE_TYPE.ACCOUNT_NOT_FOUND,
      {
        title: 'Телефон не зарегестрирован',
        msg: `Для начала необходимо зарегестрироваться в системе`,
        btnName: 'Зарегестрироваться',
        action: () => window.location.href = 'https://mtstv.mts.ru/home'
      }],
      [KION_ERROR_PAGE_TYPE.PROMO_OUTDATED, {
        title: `Промокод ${httpService.promoCode} устарел`,
        btnName: 'Ввести другой промокод',
        action: () => this.$router.push('/')
      }]
  ])

  error: ErrorScheme = {
    title: '',
    msg: `
    Неизвестная ошибка. Что-то пошло не так. <br>
    Попробуйте вернуться позже или попробовать снова
    `,
    btnName: 'Назад',
    action: () => this.$router.push('/')
  }

  navigate(link: string) {
    this.$router.push({ path: link })
  }

  mounted() {
    window.sessionStorage.removeItem("request_id")
	const errMsg = window.sessionStorage.getItem('errMsg');
	console.log('httpService.messageError', httpService.messageError)
	console.log('а есть ли у нас errMsg?', errMsg);
	if (errMsg) {
		console.log('errMsg как минимум имеется', errMsg)
		this.error = {
			title: '',
			msg: errMsg,
			btnName: 'Назад',
			action: () => this.$router.push('/')
		}
	}
    const q = this.$route.query?.type
    if (q && typeof q === 'string') {
      this.error = this.errorsMap.get(q as KION_ERROR_PAGE_TYPE) || this.error
    }
  }
}
