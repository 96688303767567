

import { Component, Vue } from 'vue-property-decorator'
import KionHeader from '@/components/Header/Header.vue'
import KionFooter from '@/components/Footer/Footer.vue'
import KionCopyright from '@/components/Shared/Copyright.vue'
import {gtm} from "@/util/GTMService";
import KionSelect from '@/components/Shared/Select.vue'
import { mainService } from '@/util/mainService'
declare global {
  interface Window {
    kionVersion: string;
  }
}
@Component({ components: { KionHeader, KionFooter, KionCopyright, KionSelect } })
export default class KionMain extends Vue {
  isFooterVisible = true
  mounted() {
    mainService.isFooterVisible.subscribe( condition => this.isFooterVisible = condition)
    gtm.clearSessionSaveOldRequestId(window.sessionStorage.getItem("request_id"), window.sessionStorage.getItem("analyticsQuery"), window.sessionStorage.getItem("selected_subscription"))
    window.kionVersion = '20-01-2022-17-55'
  }
}
